<template>
     <div
          class="nav-container fixed top-0 z-60 font-roboto tracking-ider font-bold text-sm xl:text-base 2xl:text-lg bg-moozi-1"
     >
          <nav
               class="w-full bg-moozi-1 mt-[1px]"
               style="width: 100vw !important"
          >
               <div
                    class="h-full flex-auto header-bg w-full flex flex-wrap flex-row items-center justify-between mx-auto gap-2 bg-moozi-1"
               >
                    <button
                         @click="setMenu(!menuGuest)"
                         class="flex-initial flex-wrap items-center justify-between mx-auto p-2 xs:p-4 pl-3 xs:pl-5 text-white hover:text-secondary-3 font-lg rounded-lg text-sm"
                         type="button"
                    >
                         <img
                              class="w-5 xs:w-7 h-5 xs:h-7"
                              :src="
                                   baseUrl.img +
                                   '/assets/img/header/burgermenu.svg'
                              "
                              alt=""
                         />
                    </button>

                    <div
                         class="flex-1 header-bg h-full w-full flex flex-wrap items-center justify-between pr-3 xs:!pr-5"
                    >
                         <span
                              @click="enableScroll()"
                              class="h-full"
                         >
                              <a
                                   @click="onLobby"
                                   class="flex items-center space-x-3 rtl:space-x-reverse h-16 md:h-full cursor-pointer"
                              >
                                   <img
                                        class="w-16 xs:w-18 lg:w-20 -translate-y-1.5 lg:-translate-y-1"
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/logo.webp'
                                        "
                                        alt=""
                                   />
                              </a>
                         </span>
                         <div class="flex items-center space-x-6">
                              <div class="flex flex-row space-x-2 lg:space-x-3">
                                   <!-- scale-100 hover:scale-105 -->
                                   <router-link
                                        @click="setMenuOpen()"
                                        :to="{ name: 'gold-coin-shop' }"
                                        class="w-max"
                                   >
                                        <button
                                             type="button"
                                             class="h-10 sm:h-11 flex flex-row items-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-2 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                                        >
                                             <img
                                                  class="h-5 sm:h-7"
                                                  :src="
                                                       baseUrl.img +
                                                       '/assets/img/header/gc-sc.svg'
                                                  "
                                                  alt=""
                                             />
                                             <span
                                                  class="cursor-pointer font-roboto text-xs sm:text-sm font-extrabold"
                                                  >Get Coins</span
                                             >
                                        </button>
                                   </router-link>
                                   <router-link
                                        @click="setMenuOpen('login')"
                                        :to="{ name: 'login' }"
                                        class="w-max"
                                   >
                                        <button
                                             type="button"
                                             class="text-xs sm:text-sm h-10 sm:h-11 text-secondary-3 bg-background-4 px-6 py-2.5 text-center leading-tight shadow-xl rounded-full w-full font-roboto font-bold"
                                        >
                                             Log In
                                        </button>
                                   </router-link>
                              </div>
                         </div>
                    </div>
               </div>
          </nav>
          <!-- drawer component -->
          <div
               :style="!menuGuest ? 'left:-256px' : ''"
               id="drawer-navigation"
               class="fixed left-0 z-60 overflow-hidden w-64 overflow-y-auto transform bg-moozi-2 text-shade-1 ease-in-out transition-all duration-300"
               tabindex="-1"
               aria-labelledby="drawer-navigation-label"
          >
               <div
                    class="drawer-navigation-wrapper overflow-y-auto overflow-x-hidden h-full flex flex-col relative"
               >
                    <div class="bg-moozi-2 drop-shadow-xl">
                         <div class="px-6 pb-4">
                              <div class="py-8">
                                   <img
                                        class="mx-auto w-6/12"
                                        :src="
                                             baseUrl.img +
                                             '/assets/img/header/avatar.png'
                                        "
                                        alt=""
                                   />
                              </div>
                              <div
                                   class="flex justify-center items-center gap-2"
                              >
                                   <p
                                        class="cursor-pointer text-sm leading-5 font-bold text-white text-nowrap text-ellipsis overflow-hidden"
                                   >
                                        Guest
                                   </p>
                              </div>
                         </div>
                    </div>
                    <div
                         class="flex flex-col items-start h-full justify-between"
                    >
                         <div class="flex-1 pt-6 w-full">
                              <ul
                                   class="left-navigation-container font-medium px-6"
                              >
                                   <li>
                                        <router-link
                                             @click="
                                                  setMenuOpen('gold-coin-shop')
                                             "
                                             :to="{ name: 'gold-coin-shop' }"
                                             class="flex items-center py-2 rounded-lg group cursor-pointer get-coins"
                                        >
                                             <div
                                                  class="w-full h-10 sm:h-11 flex flex-row items-center justify-center gap-1 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-2 bg-secondary-3 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                                             >
                                                  <img
                                                       class="h-5 sm:h-7"
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/gc-sc.svg'
                                                       "
                                                       alt=""
                                                  />
                                                  <span
                                                       class="cursor-pointer font-roboto text-xs sm:text-sm font-extrabold"
                                                       >Get Coins</span
                                                  >
                                             </div>
                                        </router-link>
                                   </li>
                                   <li v-if="deviceType() == 'mobile'">
                                        <div
                                             class="flex items-center justify-center py-2 rounded-lg group cursor-pointer"
                                        >
                                             <div
                                                  id="chat-button-mobile"
                                                  data-id="TPwRaUxqEJF"
                                                  class="w-full h-10 sm:h-11 flex flex-row items-center justify-center gap-3 rounded-full transition-transform duration-300 font-roboto font-bold tracking-wider px-2 bg-gradient-to-b from-yellow-10 to-yellow-20 text-primary-1 text-sm sm:text-base md:text-lg lg:text-base xl:text-lg !cursor-pointer"
                                             >
                                                  <svg
                                                       v-if="
                                                            livechatCheck
                                                                 ? ''
                                                                 : 'hidden'
                                                       "
                                                       aria-hidden="true"
                                                       class="w-8 h-8 text-primal-black animate-spin fill-primal-yellow"
                                                       viewBox="0 0 100 101"
                                                       fill="none"
                                                       xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                       <path
                                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                            fill="currentColor"
                                                       ></path>
                                                       <path
                                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                            fill="currentFill"
                                                       ></path>
                                                  </svg>
                                                  <img
                                                       v-if="
                                                            livechatCheck
                                                                 ? 'hidden'
                                                                 : ''
                                                       "
                                                       src="/assets/img/chat_cow.svg"
                                                       class="w-12 flex-initial ml-2"
                                                  />
                                                  <span
                                                       v-if="
                                                            livechatCheck
                                                                 ? 'hidden'
                                                                 : ''
                                                       "
                                                       class="flex-1 cursor-pointer font-roboto text-xs sm:text-sm font-extrabold"
                                                       >Online Chat</span
                                                  >
                                             </div>
                                        </div>
                                   </li>
                                   <li @click="enableScroll(), setMenuOpen()">
                                        <a
                                             @click="onLobby"
                                             class="flex items-center py-2 rounded-lg group cursor-pointer"
                                             :class="
                                                  isUrl('lobby')
                                                       ? 'active-left-navigation'
                                                       : ''
                                             "
                                        >
                                             <div
                                                  class="h-6 sm:h-8 w-6 sm:w-8 content-center"
                                             >
                                                  <img
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/games.svg'
                                                       "
                                                  />
                                             </div>
                                             <span
                                                  class="ms-3 font-bold text-sm sm:text-base"
                                                  >Lobby</span
                                             >
                                        </a>
                                   </li>
                                   <li @click="enableScroll(), setMenuOpen()">
                                        <router-link
                                             :to="{ name: 'promotion' }"
                                             class="flex items-center py-2 rounded-lg group"
                                             :class="
                                                  isUrl('promotion')
                                                       ? 'active-left-navigation'
                                                       : ''
                                             "
                                        >
                                             <div
                                                  class="h-6 sm:h-8 w-6 sm:w-8 content-center"
                                             >
                                                  <img
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/promotion.svg'
                                                       "
                                                  />
                                             </div>
                                             <span
                                                  class="ms-3 font-bold text-sm sm:text-base"
                                                  >Promotions</span
                                             >
                                        </router-link>
                                   </li>
                                   <li @click="enableScroll(), setMenuOpen()">
                                        <router-link
                                             :to="{ name: 'support' }"
                                             class="flex items-center py-2 rounded-lg group"
                                             :class="
                                                  isUrl('support')
                                                       ? 'active-left-navigation'
                                                       : ''
                                             "
                                        >
                                             <div
                                                  class="h-6 sm:h-8 w-6 sm:w-8 content-center"
                                             >
                                                  <img
                                                       :src="
                                                            baseUrl.img +
                                                            '/assets/img/header/support.svg'
                                                       "
                                                  />
                                             </div>
                                             <span
                                                  class="ms-3 font-bold text-sm sm:text-base"
                                                  >Support</span
                                             >
                                        </router-link>
                                   </li>
                              </ul>
                         </div>
                         <div
                              class="mt-10 mb-10 w-full text-white cursor-pointer transform ease-in-out transition-all duration-300"
                         >
                              <div
                                   class="flex flex-col items-center justify center gap-2 px-6"
                              >
                                   <router-link
                                        @click="setMenuOpen('register')"
                                        :to="{ name: 'register' }"
                                        class="flex-1 w-full"
                                   >
                                        <button
                                             type="button"
                                             class="text-sm h-10 sm:h-11 text-primary-1 bg-secondary-3 px-1 py-2.5 text-center leading-tight shadow-xl rounded-full w-full font-roboto font-bold"
                                        >
                                             Sign Up
                                        </button>
                                   </router-link>
                                   <router-link
                                        @click="setMenuOpen('login')"
                                        :to="{ name: 'login' }"
                                        class="flex-1 w-full"
                                   >
                                        <button
                                             type="button"
                                             class="text-sm h-10 sm:h-11 text-secondary-3 bg-background-4 px-1 py-2.5 text-center leading-tight shadow-xl rounded-full w-full font-roboto font-bold"
                                        >
                                             Log In
                                        </button>
                                   </router-link>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { baseUrl } from "config/services.js";
import device from "middleware/device.js";
import { utilitiesStore } from "store/utilities.js";
import eventBus from "plugins/event.js";

export default {
     setup() {
          const sidebarOpen = ref(false);
          const route = useRoute();
          const router = useRouter();

          const useUtilitiesStore = utilitiesStore();

          const { deviceType } = device();

          const livechatCheck = ref(null);
          const livechatEyeCatcherCheck = ref(null);

          const livechatInterval = ref(
               setInterval(() => {
                    livechatCheck.value = document.getElementById(
                         "chat-widget-container"
                    );

                    if (livechatCheck.value != null) {
                         if (deviceType() == "mobile") {
                              document
                                   .getElementById("chat-widget-container")
                                   ?.classList?.add("hidden");
                              document
                                   .querySelector(".livechat_button")
                                   ?.classList?.add("hidden");
                         }
                         clearInterval(livechatInterval.value);
                    }
               }, 1000)
          );

          const livechatEyeCatcherInterval = ref(
               setInterval(() => {
                    livechatEyeCatcherCheck.value = document.getElementById(
                         "livechat-eye-catcher"
                    );
                    if (livechatEyeCatcherCheck.value != null) {
                         if (deviceType() == "mobile") {
                              document
                                   .getElementById("livechat-eye-catcher")
                                   ?.classList?.add("hidden");
                         }
                         clearInterval(livechatEyeCatcherInterval.value);
                    }
               }, 1000)
          );

          const enableScroll = () => {
               useUtilitiesStore.enableScroll();
          };

          const menuGuest = computed(() => {
               return useUtilitiesStore.menuGuest;
          });

          const isUrl = (...urls) => {
               let currentUrl = route.path.substr(1);
               if (urls[0] === "") {
                    return currentUrl === "";
               }
               return urls.filter((url) => currentUrl.startsWith(url)).length;
          };

          const setMenu = (data) => {
               useUtilitiesStore.setMenuGuest(data);
          };

          const myEventHandler = () => {
               if (deviceType() == "mobile") {
                    setMenu(false);
               } else {
                    if (["login", "register", "forgot"].includes(route.name)) {
                         setMenu(false);
                         return;
                    }

                    if (window.innerWidth < 1024) {
                         setMenu(false);
                         return;
                    }

                    setMenu(true);
               }
          };

          const setMenuOpen = (type = null) => {
               if (["login", "register", "forgot"].includes(type)) {
                    setMenu(false);
                    return;
               }
               if (deviceType() == "mobile") {
                    setMenu(false);
               }
          };

          const onLobby = () => {
               if (route.name == "lobby") {
                    console.log("emit");
                    eventBus.emit("back_to_lobby");
                    eventBus.emit("clear_search_lobby");
               } else {
                    console.log("force");
                    router.push({ name: "lobby", force: true });
               }
          };

          onMounted(() => {
               myEventHandler();
               window.addEventListener("resize", function () {
                    myEventHandler();
               });
          });

          watch(livechatInterval, () => {
               livechatCheck.value = document.getElementById(
                    "chat-widget-container"
               );
          });

          watch(livechatEyeCatcherInterval, () => {
               livechatEyeCatcherCheck.value = document.getElementById(
                    "livechat-eye-catcher"
               );
          });

          return {
               sidebarOpen,
               isUrl,
               baseUrl,
               setMenuOpen,
               enableScroll,
               menuGuest,
               setMenu,
               deviceType,
               livechatCheck,
               onLobby
          };
     }
};
</script>
